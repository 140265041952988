import {
  AUTHENTICATE,
  GET_ACCESS_TOKEN,
  REQUEST_NEW_TOKEN,
  REQUEST_NEW_TOKEN_CUSTOMER,
} from "@/store/action-types";
import {
  SET_ACTIVE_CUSTOMER,
  SET_ACTIVE_TOKEN,
  SET_AUTHENTICATION_TOKEN,
} from "@/store/mutation-types";
import axios from "../../service/api";

export default {
  namespaced: true,
  state: {
    accessToken: null,
    customerId: null,
    activeCustomer: null,
  },
  getters: {
    accessToken: (state) => state.accessToken,
    customerId: (state) => state.customerId,
    activeCustomer: (state) => state.activeCustomer,
  },
  actions: {
    [AUTHENTICATE]({ commit, dispatch, state }, payload) {
      const token = payload.accessToken;
      const base64Payload = token.split(".")[1];
      const load = Buffer.from(base64Payload, "base64");
      const tokenDecoded = JSON.parse(load.toString());
      commit(SET_ACTIVE_CUSTOMER, tokenDecoded);
      commit(SET_ACTIVE_TOKEN, payload);
      commit(SET_AUTHENTICATION_TOKEN, payload.accessToken);
      if (
        tokenDecoded["Intego.NewsFeed"] === undefined ||
        state.activeCustomer.xalId === undefined
      ) {
        dispatch(REQUEST_NEW_TOKEN);
      }
      if (tokenDecoded["Impersonator"] !== undefined) {
        let customerId = tokenDecoded["CustomerId"];
        let employeeId = tokenDecoded["Intego.EmployeeId"];
        let impersonator = tokenDecoded["Impersonator"].split("@")[0];
        return axios
          .get(
            `${process.env.VUE_APP_API_URL}/authentication/logimpersonator/${customerId}/${employeeId}/${impersonator}`
          )
          .then(() => {});
      }
    },
    [REQUEST_NEW_TOKEN]({ state, commit }) {
      let employeeId = state.activeCustomer.employeeId;
      let customerId = state.customerId;
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/authentication/requestnewtoken/customer/${customerId}/employee/${employeeId}`
        )
        .then((response) => {
          const token = response.data.token;
          const base64Payload = token.split(".")[1];
          const load = Buffer.from(base64Payload, "base64");
          const tokenDecoded = JSON.parse(load.toString());
          commit(SET_ACTIVE_CUSTOMER, tokenDecoded);
          commit(SET_ACTIVE_TOKEN, { accessToken: token });
          commit(SET_AUTHENTICATION_TOKEN, token);
        });
    },
    [REQUEST_NEW_TOKEN_CUSTOMER]({ state, commit }, payload) {
      let employeeId = state.activeCustomer.employeeId;
      let customerId = payload.customerId;
      return axios
        .get(
          `${process.env.VUE_APP_API_URL}/authentication/requestnewtoken/customer/${customerId}/employee/${employeeId}`
        )
        .then((response) => {
          const token = response.data.token;
          const base64Payload = token.split(".")[1];
          const load = Buffer.from(base64Payload, "base64");
          const tokenDecoded = JSON.parse(load.toString());
          commit(SET_ACTIVE_CUSTOMER, tokenDecoded);
          commit(SET_ACTIVE_TOKEN, { accessToken: token });
          commit(SET_AUTHENTICATION_TOKEN, token);
        });
    },
    [GET_ACCESS_TOKEN]({ commit }) {
      const token = getAccessToken();
      commit(SET_AUTHENTICATION_TOKEN, token);
    },
  },
  mutations: {
    [SET_ACTIVE_CUSTOMER](state, payload) {
      const { CustomerId, EmployeeEmail, EmployeeName } = payload;
      state.customerId = CustomerId;
      state.activeCustomer = {
        name: EmployeeName,
        employeeId: payload["Intego.EmployeeId"],
        email: EmployeeEmail,
        newsFeed: payload["Intego.NewsFeed"],
        xalId: payload["CustomerXal"],
      };

      localStorage.setItem("customerId", CustomerId);
      localStorage.setItem("Email", EmployeeEmail);
      localStorage.setItem("Name", EmployeeName);
    },
    [SET_ACTIVE_TOKEN](state, payload) {
      const { accessToken } = payload;
      localStorage.setItem("token", accessToken);
    },
    // Set token to be used in all axios requests
    [SET_AUTHENTICATION_TOKEN](state, token) {
      state.accessToken = token;

      axios.interceptors.request.use((request) => {
        request.headers["Authorization"] = `Bearer ${token}`;
        return request;
      });
    },
  },
};

function getAccessToken() {
  return localStorage.getItem("token");
}
